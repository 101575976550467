<template>
    <div class="bg-gray-50 h-[100vh]">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind)
let isMobile = computed(() => {
    return breakpoints.isSmallerOrEqual('sm')
})

</script>